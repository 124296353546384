import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

const useStyles = makeStyles((theme) => ({
  markdown: {
    ...theme.typography.body2,
    padding: theme.spacing(3, 0),
  },
  list: {
    backgroundColor: theme.palette.grey[200],
    marginTop: '20px !important' 
  }
}));

export default function Clients(props) {
  const classes = useStyles();
  const { title } = props;

  return (
    <Grid item xs={12} md={8}>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Divider />
      <div className={classes.markdown}>
        <Typography variant="subtitle1" gutterBottom>
          <a>Here is a list of props I commonly use in my practice:</a>
        </Typography>
        <p>
          <Typography variant="subtitle2" gutterBottom>       
            <b>Props made from wood:</b>
          </Typography>
          <List className={classes.list}>
            <ListItem disablePadding>
              <ListItemButton component="a" href="https://www.amazon.com/s?k=japanese+bamboo+foot+massager&crid=213L2529I3KQM&sprefix=japanese+bamboo+foot+ma%2Caps%2C191&ref=nb_sb_ss_ts-doa-p_1_23" target="_blank">
                <ListItemText primary="Takefumi, bamboo" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component="a" href="https://www.optp.com/Pro-Rotating-Discs?kw=rotating%20discs" target="_blank">
                <ListItemText primary="OPTP Rotating Discs" />
              </ListItemButton>
            </ListItem>
          </List>
        </p> 
        <p>
          <Typography variant="subtitle2" gutterBottom>
            <b>Props made from squishy materials:</b>
          </Typography>
          <List className={classes.list}>
            <ListItem disablePadding>
              <ListItemButton component="a" href="https://www.amazon.com/gp/product/B08SJ3Y31W/ref=ppx_yo_dt_b_search_asin_title?ie=UTF8&th=1" target="_blank">
                <ListItemText primary="9-inch ball" secondary="(typically used partially inflated)"/>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component="a" href="https://www.amazon.com/OPTP-Franklin-Textured-Ball-Set/dp/B002YQRF1A/ref=sr_1_4?crid=3JT2DBUZV2287&keywords=eric+franklin+balls&qid=1699211238&sprefix=eric+franklin+balls%2Caps%2C128&sr=8-4" target="_blank">
                <ListItemText primary="Green Textured Ball" secondary="(set of 2)" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component="a" href="https://www.amazon.com/s?k=puffy+caterillar+toy&crid=1FHOI6FZKB27H&sprefix=puffy+caterillar+toy%2Caps%2C142&ref=nb_sb_noss" target="_blank">
                <ListItemText primary="Puffy Caterpillar Toys" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component="a" href="https://www.amazon.com/Lopenle-Urchin-Monster-Ballpoint-Christmas/dp/B0B8HB79RS/?_encoding=UTF8&pd_rd_w=ePvyb&content-id=amzn1.sym.255b3518-6e7f-495c-8611-30a58648072e%3Aamzn1.symc.a68f4ca3-28dc-4388-a2cf-24672c480d8f&pf_rd_p=255b3518-6e7f-495c-8611-30a58648072e&pf_rd_r=S58GRZJ562WVQ0BB2XDD&pd_rd_wg=gR0yn&pd_rd_r=0ec2d41f-a2ed-4831-a5fd-d6c6e53e2307&ref_=pd_hp_d_atf_ci_mcx_mr_ca_hp_atf_d" target="_blank">
                <ListItemText primary="Sea Urchin Pens" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component="a" href="https://www.amazon.com/Schylling-Ramen-Noodlies-Toy-EA/dp/B091C5HVW1/ref=sr_1_1?crid=2REPQ0EFM400V&dib=eyJ2IjoiMSJ9.TAMIlNUN58Ds7fwO_KW8ZyL0kXSXS1GuHwzkCm8S0LHFk6O_C9ebfHrlos6Zuephu2Kyinps9em9W8z-ghA61dKIWNTdPUUs_1ARfI_YGqJIFYhOpOnlQgz95apanxaSmXEDspJ8xEq1apjXFNUvxLrH92855XMheJjxGyJaPjFhknvykw7kxaKZUXosdAd85TC9_dIR69Yia7PUmHvWfLal6MEi3ptxgYlC_rVzSEfIUfymHnv51w5rwA3Qymf3vWs2Pv9GTxSSf4iee7FTMme3oeAQt0MikIUIpR4Nmkg.l5uz9mU19IxXpOyBymflprBRRKFfK9JTKHMWSrhkZA4&dib_tag=se&keywords=silicone+ramen+toy&qid=1731477227&sprefix=silicone+ramen+toy%2Caps%2C185&sr=8-1" target="_blank">
                <ListItemText primary="Silicone Ramen" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component="a" href="https://www.amazon.com/gp/product/B082D7WVT8/ref=ppx_yo_dt_b_asin_title_o00_s00?ie=UTF8&th=1" target="_blank">
                <ListItemText primary="Textured String Fidget Toy" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component="a" href="https://www.amazon.com/OPTP-Franklin-Mini-Roll-LE9002/dp/B002C9KV42/ref=sr_1_1?crid=1P1AOOOL3Q1J1&keywords=eric+franklin+mini+roll&qid=1699211310&sprefix=eric+franklin+mini+roll%2Caps%2C106&sr=8-1" target="_blank">
                <ListItemText primary="Peanuts" secondary="(sold in singles, but suggest getting 2)" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component="a" href="https://www.amazon.com/ProSource-Diameter-Balance-Cushion-Exercise/dp/B00DYA3FIC/ref=sr_1_7?keywords=sit%2Bdisc%2Bfor%2Badults&qid=1686003452&sprefix=sit%2Bdisc%2Caps%2C328&sr=8-7&th=1" target="_blank">
                <ListItemText primary="Sit Disc" />
              </ListItemButton>
            </ListItem>
          </List>
        </p>
        <p>       
          <Typography variant="subtitle2" gutterBottom>
            <b>Props made from foam:</b>
          </Typography>       
          <List className={classes.list}>
            <ListItem disablePadding>
              <ListItemButton component="a" href="https://www.amazon.com/OPTP-Performance-Wedges-Supports-Physical/dp/B079LLKHD5/ref=sr_1_3?crid=3H4HDDYMGZ045&keywords=optp+performance+wedges&qid=1699211350&sprefix=optp+performan%2Caps%2C110&sr=8-3" target="_blank">
                <ListItemText primary="OPTP Blue Wedges" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component="a" href="https://www.amazon.com/Performance-Block-Physical-Stretching-Functional/dp/B079K21Q1N" target="_blank">
                <ListItemText primary="OPTP Black Saddle" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component="a" href="https://www.amazon.com/OPTP-SMR36-SmartRoller®/dp/B00FXTV89K/ref=sr_1_1?crid=RMM7TOWNKJJK&keywords=optp+smart+roller&qid=1699211444&sprefix=optp+smart+roller%2Caps%2C105&sr=8-1&ufe=app_do%3Aamzn1.fos.18630bbb-fcbb-42f8-9767-857e17e03685" target="_blank">
                <ListItemText primary="OPTP Purple Smart Roller" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component="a" href="https://www.optp.com/Cobblefoam-Uneven-Surface-Balance-Trainer?kw=optp%20cobble" target="_blank">
                <ListItemText primary="OPTP Cobblefoam Balance Trainer" />
              </ListItemButton>
            </ListItem>
          </List>
        </p>
        <p>
          <Typography variant="subtitle2" gutterBottom>
            <b>Other:</b>
          </Typography>
          <List className={classes.list}>
            <ListItem disablePadding>
              <ListItemButton component="a" href="https://www.bellicon.com/?acc=SylviaLim&a_bid=f1c026c9" target="_blank">
                <ListItemText primary="Bellicon Rebounder" secondary="(this is an Affiliate link)" />
              </ListItemButton>
            </ListItem>
          </List>
        </p>
      </div>     
    </Grid>
  );
}

Clients.propTypes = {
  title: PropTypes.string,
};
